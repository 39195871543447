import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ListCustomersRequest, ListCustomersResponse } from 'src/app/shared/api-structures/admin/customer';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { CustomerService } from '../../services/customer.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomFieldSettingsService } from '../../services/customFieldSettings.service';
import { CustomFieldSettingsWithId } from 'src/app/shared/api-structures/admin/customFieldSettings';
import { AddCustomerRequest } from 'src/app/shared/api-structures/admin/customer';
import { LatLng } from 'src/app/shared/api-structures/common';
import { GoogleMapsService } from 'src/app/shared/services/google-maps.service';
import { Observable } from 'rxjs';
import { LanguageService } from 'src/app/shared/services/language.service';
import { MatTableDataSource } from '@angular/material/table';
import { SortDirection } from '@angular/material/sort';
import { saveAs } from "file-saver";
import * as Papa from 'papaparse';
import { Router } from '@angular/router';
import { CustomerAssociatedUsersDialogComponent } from 'src/app/shared/components/associations/associated-users/associated-users.component';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import _ from 'underscore';
import { mockCsvData } from './csvTemplateData'

@Component({
  selector: 'customer-management',
  templateUrl: './customer-management.component.html',
  styleUrls: ['./customer-management.component.scss']
})

export class CustomerManagementComponent extends AutoDestroy implements OnInit {
  displayedColumns = ['id', 'name', 'address', 'territory', 'category', 'chain', 'area', 'lastUpdate', 'externalId', 'users', 'edit', 'delete']
  data = []
  dataSource: MatTableDataSource<Record<string, string>>
  currentPage = 0
  pageSize = 25
  query = ''
  sortBy = 'id'
  sortDirection: SortDirection = 'asc'
  totalItens
  listUsersRequest: ListCustomersRequest
  listCustomerResponse: ListCustomersResponse
  listCustomerResponseForCsv: ListCustomersResponse
  customFields: CustomFieldSettingsWithId[]
  //customFields: CustomFieldConfig[]
  csvContent: string
  csvHeader: string[] = [];
  isShowConfirmDeleteDialog = false
  modalFilters = {
    dateFrom: null,
    dateTo: null,
  }

  importDialogData: any = {
    template: mockCsvData,
    title: "Customers",
    id: "Customers",
  };

  externalId: string
  name: string
  category: string
  address: string
  territory: string
  chain: string
  latLng: LatLng
  taxId: string
  customerCode: string
  lsMessages: string[] = []
  isShowModal = false

  autocompleteInput: string;
  queryWait: boolean;
  apiLoaded: Observable<boolean>;
  geocoder: google.maps.Geocoder;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();

  constructor(public dialog: MatDialog,
    private customerService: CustomerService,
    private customFieldSettingsService: CustomFieldSettingsService,
    private googleMapsService: GoogleMapsService,
    private languageService: LanguageService,
    private router: Router) {
    super()
    this.apiLoaded = this.googleMapsService.loadApi()
    this.listUsersRequest = { currentPage: this.currentPage, query: '', pageSize: this.pageSize }
  }

  ngOnInit() {
    this.fetchCustomFields()
    this.apiLoaded.subscribe((res) => {
      if (res)         
        console.log('google maps worked')
      else
        console.log('google maps failed')
    })

    this.refresh()
    this.customerService.listCustomers$.subscribe(d => {
      this.listCustomerResponseForCsv = d
      d.items.map(i => {
        i.address = i.addresses?.[0] ?? i.address
        i.territory = i.territories?.[0] ?? i.territory
      })
      this.data = d.items
      this.totalItens = d.totalItems
    })
  }

  onUsers(customer) {
    const dialogRef = this.dialog.open(CustomerAssociatedUsersDialogComponent, {
      panelClass: 'no-padding-dialog-container',
      width: '80vw',
      maxHeight: '95vh',
      data: { id: customer.id, name: customer.name, associationType: 'customer' }
    })
  }

  onEdit(customer) {
    this.router.navigate(['/admin/manage-customer'], { queryParams: { customer_id: customer.id } })
  }

  async onDelete(event) {
    await this.customerService.deleteCustomer({ id: event.id })
    this.refresh()
  }

  onPaginate({ pageIndex, pageSize }) {
    this.currentPage = pageIndex
    this.pageSize = pageSize
    this.customerService.loadCustomers({currentPage: this.currentPage, pageSize: this.pageSize, query: this.query})
  }

  refresh() {
    this.customerService.loadCustomers(this.listUsersRequest)
  }

  onFilter(event) {
    this.customerService.loadCustomers({currentPage: this.currentPage, pageSize: this.pageSize, query: event, modalFilters: this.modalFilters})
  }

  onModalFilters(event) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: "500px",
      data: this.modalFilters,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data && !_.isEqual(this.modalFilters, data)) {
        this.modalFilters = data;
        
        this.onFilter(this.query)
      }
    });
  }

  async add() {
    await this.router.navigateByUrl('/admin/manage-customer')
  }

  async fetchCustomFields() {
    const res = await this.customFieldSettingsService.listCustomFieldsByEntity({ id: 'customers' })
    this.customFields = res.items
  }

  afterConfirmModal() {
    this.lsMessages = []
    this.isShowModal = false
    this.refresh()
  }

  async exportCustomers() {
    const res = await this.customerService.exportCustomers()
    saveAs(res.url, "price-targets.csv")
  }

  uploadCsvFileBtnClick() {
    (document.querySelector("#importCsvFileInput") as HTMLElement).click();
  }

  async importCsvFile(event: HTMLInputElement) {
    this.lsMessages = [];
    const file = event[0];
    this.isShowModal = true;
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        this.csvContent = reader.result?.toString();
  
        // Use papaparse to parse the CSV content
        Papa.parse(this.csvContent, {
          header: false, // Treat the first row as headers          
          skipEmptyLines: true,
          complete: async (result) => {
            const rows = result.data;
            this.extractCsvHeader(rows[0]); // Pass the header row
            
            if (this.lsMessages.length === 0) {
              for (let i = 1; i < rows.length; i++) {
                let skipItem: boolean = false
                const fields = rows[i];
                if (fields.length === this.csvHeader.length) {

                  const customer = new AddCustomerRequest()
                  customer.externalId = fields[0]
                  customer.name = fields[1]
                  customer.category = fields[2]
                  customer.address = fields[3]
                  customer.location = undefined
                  customer.territory = fields[5]
                  customer.chain = fields[6]
                  customer.taxId = fields[7]
                  customer.customerCode = fields[8]
    
    
                  if (!customer.name) {
                    skipItem = true
                    this.lsMessages.push(this.languageService.translateSync('MissingNameAtLine') + ' ' + (i + 2))
                  }
    
                  if (!customer.category) {
                    skipItem = true
                    this.lsMessages.push(this.languageService.translateSync('MissingCategoryAtLine') + ' ' + (i + 2))
                  }
    
                  if (!customer.address) {
                    skipItem = true
                    this.lsMessages.push(this.languageService.translateSync('MissingAddressAtLine') + + ' ' + (i + 2))
                  }
    
                  if (!customer.taxId) {
                    skipItem = true
                    this.lsMessages.push(this.languageService.translateSync('MissingTaxIdAtLine') + + ' ' + (i + 2))
                  }
    
                  if (!customer.customerCode) {
                    skipItem = true
                    this.lsMessages.push(this.languageService.translateSync('MissingCustomerCodeAtLine') + ' ' + (i + 2))
                  }
                  else {
                    if (this.listCustomerResponseForCsv.items.find(x => x.customerCode === customer.customerCode)) {
                      skipItem = true
                      this.lsMessages.push(this.languageService.translateSync('CustomerCodeAlreadyExistAtLine') + ' ' + (i + 2))
                    }
                  }
    
                  if (skipItem === false) {
    
                    const tempAddress = customer.address
                    const myPlaces = await this.googleMapsService.getPlaces(fields[3])
                    if (myPlaces.length > 0) {
                      const myGeoLocation = await this.googleMapsService.getGeoLocationOfPlace(myPlaces[0]);
                      if (myGeoLocation !== undefined) {
                        customer.address = myGeoLocation.foundAddress
                        customer.location = myGeoLocation.latLng
                      }
                      this.save(customer)
                      this.lsMessages.push(this.languageService.translateSync('SuccessAtLine') + ' ' + (i + 2))
                      // this.lsMessages.push(this.languageService.translateSync('Address') + " " + tempAddress + " " + this.languageService.translateSync('FoundAs') + " " + customer.address + (i+2)) 
                    }
                    else {
                      skipItem = true
                      this.lsMessages.push(this.languageService.translateSync('AddressNotFoundGoogleMaps') + ' ' + (i + 2))
                    }
                  }
                } else {
                  this.lsMessages.push(this.languageService.translateSync('MoreColumnsThanRecordsAtLine') + (i + 2))
                }
  
                // Your processing logic here, fields is an object with column names as keys
              }
            }
          },
          error: (error) => {
            // Handle parsing errors
            console.error('CSV Parsing Error:', error);
          },
        });
      };
      reader.readAsText(file);
    }
  }

  async importCsvFile1(event: HTMLInputElement) {
    // const url = await this.appService.uploadFileToStorageAndCompressIfNeedded(event.files[0], undefined, true)
    // await this.customerService.importCustomers(url)// importPriceTargets
    // this.refresh()

    this.lsMessages = [];
    const file = event.files[0];
    this.isShowModal = true;
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        this.csvContent = reader.result?.toString();
  
        // Use papaparse to parse the CSV content
        Papa.parse(this.csvContent, {
          header: false, // Treat the first row as headers          
          skipEmptyLines: true,
          complete: async (result) => {
            const rows = result.data;
            this.extractCsvHeader(rows[0]); // Pass the header row
            
            if (this.lsMessages.length === 0) {
              for (let i = 1; i < rows.length; i++) {
                let skipItem: boolean = false
                const fields = rows[i];
                if (fields.length === this.csvHeader.length) {

                  const customer = new AddCustomerRequest()
                  customer.externalId = fields[0]
                  customer.name = fields[1]
                  customer.category = fields[2]
                  customer.taxId = fields[3]
                  customer.customerCode = fields[4]
                  customer.area = fields[5]
                  customer.chain = fields[6]
                  customer.territory = fields[7]
                  customer.address = fields[8]
                  customer.lastUpdate = fields[9]
    
                  if (!customer.name) {
                    skipItem = true
                    this.lsMessages.push(this.languageService.translateSync('MissingNameAtLine') + ' ' + (i + 2))
                  }
    
                  if (!customer.category) {
                    skipItem = true
                    this.lsMessages.push(this.languageService.translateSync('MissingCategoryAtLine') + ' ' + (i + 2))
                  }
    
                  if (!customer.address) {
                    skipItem = true
                    this.lsMessages.push(this.languageService.translateSync('MissingAddressAtLine') + + ' ' + (i + 2))
                  }
    
                  if (!customer.taxId) {
                    skipItem = true
                    this.lsMessages.push(this.languageService.translateSync('MissingTaxIdAtLine') + + ' ' + (i + 2))
                  }
    
                  if (!customer.customerCode) {
                    skipItem = true
                    this.lsMessages.push(this.languageService.translateSync('MissingCustomerCodeAtLine') + ' ' + (i + 2))
                  }
                  else {
                    if (this.listCustomerResponseForCsv.items.find(x => x.customerCode === customer.customerCode)) {
                      skipItem = true
                      this.lsMessages.push(this.languageService.translateSync('CustomerCodeAlreadyExistAtLine') + ' ' + (i + 2))
                    }
                  }
    
                  if (skipItem === false) {
    
                    const tempAddress = customer.address
                    const myPlaces = await this.googleMapsService.getPlaces(fields[3])
                    if (myPlaces.length > 0) {
                      const myGeoLocation = await this.googleMapsService.getGeoLocationOfPlace(myPlaces[0]);
                      if (myGeoLocation !== undefined) {
                        customer.address = myGeoLocation.foundAddress
                        customer.location = myGeoLocation.latLng
                      }
                      this.save(customer)
                      this.lsMessages.push(this.languageService.translateSync('SuccessAtLine') + ' ' + (i + 2))
                      // this.lsMessages.push(this.languageService.translateSync('Address') + " " + tempAddress + " " + this.languageService.translateSync('FoundAs') + " " + customer.address + (i+2)) 
                    }
                    else {
                      skipItem = true
                      this.lsMessages.push(this.languageService.translateSync('AddressNotFoundGoogleMaps') + ' ' + (i + 2))
                    }
                  }
                } else {
                  this.lsMessages.push(this.languageService.translateSync('MoreColumnsThanRecordsAtLine') + (i + 2))
                }
  
                // Your processing logic here, fields is an object with column names as keys
              }
            }
          },
          error: (error) => {
            // Handle parsing errors
            console.error('CSV Parsing Error:', error);
          },
        });
      };
      reader.readAsText(file);
    }
  }
  
  extractCsvHeader(headerRow) {
    if (!headerRow) return []; // Return an empty array if headerRow is not available
  
    const requiredFields = [
      'ExternalId',
      'Name',
      'Category',
      'Address',
      'Location',
      'Territory',
      'Chain',
      'TaxId',
      'CustomerCode',
    ];
    
    this.csvHeader = headerRow.map(str => str.toLowerCase());
    const missingFields = requiredFields.filter(field => !this.csvHeader.includes(field.toLowerCase()));
  
    for (const item of missingFields) {
      this.lsMessages.push(this.languageService.translateSync(`MissingField${item}`));
    }
  }  

  isFieldPresent(fieldName: string): boolean {
    return this.csvHeader.includes(fieldName);
  }

  async save(customer: AddCustomerRequest) {
    await this.customerService.addCustomer(customer)
  }

} 
