import { Injectable } from '@angular/core';
import { GetDashboardUrl, GetMobileReportsUrlResponse, GetTotalReports } from '../shared/api-structures/admin/dashboard';
import { ByIdRequest, ByIdsRequest, SortDirection } from '../shared/api-structures/common';
import { CompanySettingsResponse } from '../shared/api-structures/misc';
import { AddImageCatalogRequest, AddImageCatalogResponse, DeleteImageCatalogRequest, DeleteImageFromImageCatalogRequest, GetExistingProductsCatalogs, GetProductResponse, ListBrandsWithIds, ListCatalogImagesRequest, ListCatalogImagesResponse, ListCategoriesWithIds, SearchProducts, SearchProductsForPlanogramPlanning, UpdateImageCatalogRequest, UpdateImageCatalogResponse, UpdatePrimaryCatalogRequest, UpdatePrimaryImageCatalogRequest, UploadImagesToCatalog } from '../shared/api-structures/misc/catalog';
import { AddCategoryRequest, AddCategoryResponse } from '../shared/api-structures/misc/category';
import { AddBrandRequest, AddBrandResponse } from '../shared/api-structures/misc/brand';
import { AddEquipmentRequest, AddEquipmentResponse, ListEquipmentResponse, ListEquipmentsRequest, SearchEquipmentsRequestFilters } from '../shared/api-structures/misc/equipment';
import { CreateSignedUrlRequest, CreateSignedUrlRsponse, ListStaticModels } from '../shared/api-structures/misc/misc';
import { AddCustomerPlanogramRequest, AddCustomerPlanogramResponse, AddPlanogramRequest, AddUserPlanogramRequest, AddUserPlanogramResponse, DeleteCustomerPlanogramRequest, DeleteUserPlanogramRequest, DuplicatePlanogramRequest, GetPlanogramResponse, ListAssociatedCustomersToPlanogramRequest, ListAssociatedCustomresToPlanogramResponse, ListAssociatedUsersToPlanogram, ListAssociatedUsersToPlanogramRequest, ListPlanogramsByProductIdRequest, ListPlanogramsByProductIdResponse, ListPlanogramsForAutocompleteRequest, ListPlanogramsForAutocompleteResponse, PlanogramWithId, PlanogramsImageCatalogRequest, PlanogramsImageCatalogResponse, PublishPlanogramRequest, SearchPlanogramsRequest, SearchPlanogramsResponse, SetPlanogramFromImageRequest, SetPlanogramFromImageResponse, UpdateImageCatalogFromPlanogramsRequest, UpdatePlanogramRequest } from '../shared/api-structures/misc/planogram';
import { AddProductRequest, BaseProductWithId, CountAllProductsResponse, EditProductRequest, ExportResponse, ImportRequest, ProductsByLabelsResponse, SearchProductsRequest, SearchProductsRequestFilters, SearchProductsRequestFiltersPlanogram } from '../shared/api-structures/misc/product';
import { ComplianceReport } from '../shared/api-structures/misc/reports';
import { ApproveImages, CountImagesToApproveResponse, CountProductCatalogImagesRequest, CountProductCatalogImagesResponse, ListImageCatalogsWithImagesToApprove, ListImagesToApprove, ListImagesToApproveRequest, ListProductsWithImagesToApprove, ListProductsWithImagesToApproveRequest, SourceType } from '../shared/api-structures/misc/toApprove';
import { PLANOGRAM_STATUS } from '../shared/const';
import { ApiService } from '../shared/services/api.service';
import { LanguageService } from '../shared/services/language.service';
import { GetUserInfoResponse, UpdateUserLanguageRequest, UpdateUserProfileImageRequest } from '../shared/api-structures/admin/user';
import { GetAllNotifications } from '../shared/api-structures/misc/notification';
import { PlanogramSettingsGettingRequest, PlanogramSettingsGettingResponse } from '../shared/api-structures/misc/planogramSettings';

@Injectable({ providedIn: 'root' })
export class Dal {

  constructor(private api: ApiService, private languageService: LanguageService) { }

  // Equipment
  async addEquipment(data: AddEquipmentRequest) {
    const result = await this.api.miscRequest({
      endpoint: '/addEquipment',
      body: data,
      responseType: AddEquipmentResponse,
      errorMessage: this.languageService.translateSync('ErrorAddingEquipment'),
    })
    return result
  }

  async getEquipments(filters: string[], modalFilters?: SearchEquipmentsRequestFilters) {
    const result =
      await this.api.miscRequest<ListEquipmentsRequest, ListEquipmentResponse>({
        endpoint: '/listEquipments',
        body: { filters, modalFilters },
        responseType: ListEquipmentResponse,
        errorMessage: this.languageService.translateSync('ErrorLoadingEquipments'),
      })
    return result.arr
  }

  async deleteEquipment(id: string) {
    await this.api.miscRequest<ByIdRequest>({
      endpoint: '/deleteEquipment',
      body: { id },
      errorMessage: this.languageService.translateSync('ErrorOnDeletingEquipment'),
    })
  }

  //dashboard
  async getDashboardUrl() {
    const response = await this.api.miscRequest({ endpoint: '/getDashboardUrl', responseType: GetDashboardUrl.Response })
    return response
  }

  // Planogram
  async addPlanogram(data: AddPlanogramRequest) {
    const result = await this.api.miscRequest<AddPlanogramRequest, GetPlanogramResponse>({
      endpoint: '/addPlanogram',
      body: data,
      responseType: GetPlanogramResponse,
      errorMessage: this.languageService.translateSync('ErrorAddingPlanogram'),
    })

    return result
  }

  async getPlanogram(planogramId: string) {
    const result = await this.api.miscRequest<ByIdRequest, GetPlanogramResponse>({
      endpoint: '/getPlanogram',
      body: { id: planogramId },
      responseType: GetPlanogramResponse,
      errorMessage: this.languageService.translateSync('ErrorLoadingPlanogram'),
    })

    return result
  }

  async updatePlanogram(planogram: PlanogramWithId, planogramTmpImage?: string) {
    const result = await this.api.miscRequest<UpdatePlanogramRequest, GetPlanogramResponse>({
      endpoint: '/updatePlanogram',
      body: { planogram, planogramTmpImage },
      responseType: GetPlanogramResponse,
      errorMessage: this.languageService.translateSync('ErrorUpdateingPlanogram'),
    })

    return result
  }

  async deletePlanogram(id: string) {
    await this.api.miscRequest<ByIdRequest>({
      endpoint: '/deletePlanogram', body: { id },
      errorMessage: this.languageService.translateSync('ErrorDeletingPlanogram'),
    })
  }

  async inactivatePlanogram(id: string) {
    await this.api.miscRequest<ByIdRequest>({
      endpoint: '/inactivatePlanogram', body: { id },
      errorMessage: this.languageService.translateSync('ErrorInactivingPlanogram'),
    })
  }

  async publishPlanogram(planogramId: string, mc1PlanogramId?: string) {
    const result = await this.api.miscRequest<PublishPlanogramRequest, GetPlanogramResponse>({
      endpoint: '/publishPlanogram',
      body: { id: planogramId, mc1PlanogramId },
      responseType: GetPlanogramResponse,
      errorMessage: this.languageService.translateSync('ErrorPublishingPlanogram')
    })

    return result
  }

  async duplicatePlanogram(originalPlanogramId: string, newPlanogramName: string) {
    const result = await this.api.miscRequest<DuplicatePlanogramRequest, GetPlanogramResponse>({
      endpoint: '/duplicatePlanogram',
      body: { originalPlanogramId, newPlanogramName },
      responseType: GetPlanogramResponse,
      errorMessage: this.languageService.translateSync('ErrorCopyingPlanogram')
    })

    return result
  }

  async listPlanogramsForAutocomplete(query: string, mc1PlanogramId?: string) {
    const result = await this.api.miscRequest<ListPlanogramsForAutocompleteRequest, ListPlanogramsForAutocompleteResponse>({
      endpoint: '/listPlanogramsForAutocomplete',
      body: { query, mc1PlanogramId },
      responseType: ListPlanogramsForAutocompleteResponse,
      errorMessage: this.languageService.translateSync('ErrorLoadingPlanogramsForAutoComplete')
    }, { isHideProgress: true })

    return result.arr
  }

  async searchPlanograms(mc1PlanogramId: string, currentPage: number, pageSize: number, query: string, filterByState: PLANOGRAM_STATUS, sortBy?: string, sortDirection?: SortDirection) {
    const result = await this.api.miscRequest<SearchPlanogramsRequest, SearchPlanogramsResponse>({
      endpoint: '/searchPlanograms',
      body: { mc1PlanogramId, currentPage, pageSize, query, filterByState, sortBy: sortBy as any, sortDirection },
      responseType: SearchPlanogramsResponse,
      errorMessage: this.languageService.translateSync('ErrorLoadingPlanograms')
    })

    return result
  }

  async listPlanogramsByProductId(productId: string, mc1PlanogramId?: string) {
    const result = await this.api.miscRequest<ListPlanogramsByProductIdRequest, ListPlanogramsByProductIdResponse>({
      endpoint: '/listPlanogramsByProductId',
      body: { productId, mc1PlanogramId },
      responseType: ListPlanogramsByProductIdResponse,
      errorMessage: this.languageService.translateSync('ErrorLoadingPlanograms')
    })

    return result
  }

  async getPlanogramFromCatalog(id: string, mc1ProductId: string) {
    const result = await this.api.miscRequest<PlanogramsImageCatalogRequest, PlanogramsImageCatalogResponse>({
      endpoint: '/planogramsFromImageCatalog',
      body: { productId: mc1ProductId, catalogId: id },
      responseType: PlanogramsImageCatalogResponse,
      errorMessage: this.languageService.translateSync('ErrorLoadingPlanograms')
    })

    return result
  }

  // planogram associations
  async addAssociatedUsersToPlanogram(data: AddUserPlanogramRequest) {
    const result = await this.api.miscRequest<AddUserPlanogramRequest, AddUserPlanogramResponse>({
      endpoint: '/addAssociatedUsersToPlanogram',
      body: data,
      responseType: AddUserPlanogramResponse,
      errorMessage: this.languageService.translateSync('ErrorAddingUserPlanogram'),
    })

    return result

  }

  async deleteAssociatedUsersToPlanogram(data: DeleteUserPlanogramRequest) {
    const result = await this.api.miscRequest<DeleteUserPlanogramRequest>({
      endpoint: '/deleteAssociatedUsersToPlanogram',
      body: data,
      errorMessage: this.languageService.translateSync('ErrorDeletingUserPlanogram'),
    })

    return result

  }

  async listAssociatedUsersToPlanogram(data: ListAssociatedUsersToPlanogramRequest) {
    const result = await this.api.miscRequest<ListAssociatedUsersToPlanogramRequest, ListAssociatedUsersToPlanogram.Response>({
      endpoint: '/listAssociatedUsersToPlanogram',
      body: data,
      responseType: ListAssociatedUsersToPlanogram.Response,
      errorMessage: this.languageService.translateSync('ErrorLoadingAssociatedUsersToPlanogram')
    })

    return result

  }

  async addAssociatedCustomersToPlanogram(data: AddCustomerPlanogramRequest) {
    const result = await this.api.miscRequest<AddCustomerPlanogramRequest, AddCustomerPlanogramResponse>({
      endpoint: '/addAssociatedCustomrsToPlanogram',
      body: data,
      responseType: AddCustomerPlanogramResponse,
      errorMessage: this.languageService.translateSync('ErrorAddingCustomerPlanogram'),
    })

    return result

  }

  async deleteAssociatedCustomresToPlanogram(data: DeleteCustomerPlanogramRequest) {
    const result = await this.api.miscRequest<DeleteCustomerPlanogramRequest>({
      endpoint: '/deleteAssociatedCustomersToPlanogram',
      body: data,
      errorMessage: this.languageService.translateSync('ErrorDeletingCustomerPlanogram'),
    })

    return result

  }

  async listAssociatedCustomersToPlanogram(data: ListAssociatedCustomersToPlanogramRequest) {
    const result = await this.api.miscRequest<ListAssociatedCustomersToPlanogramRequest, ListAssociatedCustomresToPlanogramResponse>({
      endpoint: '/listAssociatedCustomersToPlanogram',
      body: data,
      responseType: ListAssociatedCustomresToPlanogramResponse,
      errorMessage: this.languageService.translateSync('ErrorLoadingAssociatedCustomersToPlanogram')
    })

    return result

  }

  async updateImageCatalogFromPlanograms(planograms: PlanogramWithId[], imageCatalogId: string, imgUrl: string) {
    await this.api.miscRequest<UpdateImageCatalogFromPlanogramsRequest>({
      endpoint: '/updateImageCatalog',
      body: { planograms, imageCatalogId, imgUrl },
      errorMessage: this.languageService.translateSync('ErrorLoadingPlanograms')
    })
  }

  async updateInfoCatalogFromPlanograms(planograms: PlanogramWithId[], catalog: AddImageCatalogRequest) {
    await this.api.miscRequest<{ planograms: PlanogramWithId[], catalog: AddImageCatalogRequest }>({
      endpoint: '/updateInfoCatalog',
      body: { planograms, catalog },
      errorMessage: this.languageService.translateSync('ErrorLoadingPlanograms')
    })
  }

  // #region CommonData

  async getPlanogramSettings() {
    const response = await this.api.miscRequest<PlanogramSettingsGettingRequest, PlanogramSettingsGettingResponse>({
      endpoint: '/getPlanogramSettings',
      body: {},
      responseType: PlanogramSettingsGettingResponse,
      errorMessage: this.languageService.translateSync('ErrorPlanogramSettingsGetting'),
    })

    return response
  }

  // #endregion

  // Catalog
  // TODO maybe change mc1CatalogId somthing else
  async addImagesCatalog(mc1ProductId: string | undefined, name: string, height: number, width: number, file: string, depth?: number, isHideProgress?: boolean) {
    const result = await this.api.miscRequest<AddImageCatalogRequest, AddImageCatalogResponse>({
      endpoint: '/addImageCatalog',
      body: { mc1ProductId, name, height, width, depth, file },
      responseType: AddImageCatalogResponse,
      errorMessage: this.languageService.translateSync('ErrorAddingImagesCatalog')
    }, { isHideProgress })

    return result
  }

  async listCatalogImages(data: ListCatalogImagesRequest) {
    const result = await this.api.miscRequest<ListCatalogImagesRequest, ListCatalogImagesResponse>({
      endpoint: '/listCatalogImages',
      body: data,
      responseType: ListCatalogImagesResponse,
      errorMessage: this.languageService.translateSync('ErrorLoadingCatalogImages')
    })

    return result
  }

  async addProduct(data: AddProductRequest) {
    const result = await this.api.miscRequest<AddProductRequest, BaseProductWithId>({
      endpoint: '/addProduct',
      body: data,
      responseType: BaseProductWithId,
      errorMessage: this.languageService.translateSync('ErrorAddingProduct'),
    }, { isHideProgress: false })

    return result
  }

  async getProduct(mc1ProductId: string) {
    const result = await this.api.miscRequest<ByIdRequest, GetProductResponse>({
      endpoint: '/getProduct',
      body: { id: mc1ProductId },
      responseType: GetProductResponse,
      errorMessage: this.languageService.translateSync('ErrorLoadingProduct')
    })

    return result
  }

  async editProduct(product: EditProductRequest) {
    const result = await this.api.miscRequest<EditProductRequest, BaseProductWithId>({
      endpoint: '/editProduct',
      body: product,
      responseType: BaseProductWithId,
      errorMessage: this.languageService.translateSync('ErrorEditingProduct')
    })

    return result
  }

  async searchProductsForPlanogramPlanning(isHideProgress: boolean, currentPage: number, pageSize: number, filters: string[],
    onlyWithImageCatalog: boolean, modalFilters?: SearchProductsRequestFiltersPlanogram, sortBy?: 'none' | 'name' | 'totalImageCatalogs', sortDirection?: SortDirection) {
    const result = await this.api.miscRequest<SearchProductsRequest, SearchProductsForPlanogramPlanning.Response>({
      endpoint: '/searchProductsForPlanogramPlanning',
      body: { currentPage, pageSize, filters, sortBy, sortDirection, onlyWithImageCatalog, modalFilters },
      responseType: SearchProductsForPlanogramPlanning.Response,
      errorMessage: this.languageService.translateSync('ErrorLoadingProductsForPlanogramPlanning')
    }, { isHideProgress })

    return result
  }

  async searchProductsForPlanogramPlanningDifferingCatalog(isHideProgress: boolean, currentPage: number, pageSize: number, filters: string[],
    onlyWithImageCatalog: boolean, newFilters?: SearchProductsRequestFilters, modalFilters?: SearchProductsRequestFiltersPlanogram, sortBy?: 'none' | 'name' | 'totalImageCatalogs', sortDirection?: SortDirection) {
    const result = await this.api.miscRequest<SearchProductsRequest, SearchProductsForPlanogramPlanning.Response>({
      endpoint: '/searchProductsForPlanogramPlanningDifferingCatalog',
      body: { currentPage, pageSize, filters, sortBy, sortDirection, onlyWithImageCatalog, newFilters, modalFilters },
      responseType: SearchProductsForPlanogramPlanning.Response,
      errorMessage: this.languageService.translateSync('ErrorLoadingProductsForPlanogramPlanning')
    }, { isHideProgress })

    return result
  }

  async countAllProducts() {
    const result = await this.api.miscRequest({
      endpoint: '/countAllProducts',
      responseType: CountAllProductsResponse,
      errorMessage: this.languageService.translateSync('ErrorLoadingProducts')
    })

    return result
  }

  async getUserName() {
    const result = await this.api.miscRequest({
      endpoint: '/getUserName',
      responseType: String,
      errorMessage: this.languageService.translateSync('ErrorLoadingProducts')
    })

    return result
  }

  async getUserInfo() {
    const result = await this.api.miscRequest({
      endpoint: '/getUserInfo',
      responseType: GetUserInfoResponse,
      errorMessage: this.languageService.translateSync('ErrorLoadingProducts')
    })

    return result
  }

  async updateUserProfileImage(data: UpdateUserProfileImageRequest) {
    await this.api.miscRequest({
      endpoint: '/updateUserProfileImage',
      body: data,
      errorMessage: this.languageService.translateSync('ErrorUpdatingUserProfileImage') // TODO translate
    })
  }

  async removeUserProfileImage(data: ByIdRequest) {
    await this.api.miscRequest({
      endpoint: '/deleteUserProfileImage',
      body: data,
      errorMessage: this.languageService.translateSync('ErrorRemovingUserProfileImage') // TODO translate
    })
  }

  async updateUserLanguage(data: UpdateUserLanguageRequest) {
    await this.api.miscRequest({
      endpoint: '/updateUserLanguage',
      body: data,
      errorMessage: this.languageService.translateSync('ErrorUpdatingUserLanguage')
    })
  }

  // TODO check sortby options 
  async searchProducts(currentPage: number, pageSize: number, filters: string[], newFilters: SearchProductsRequestFilters, onlyWithImageCatalog: boolean, sortBy?: SearchProductsRequest['sortBy'], sortDirection?: SortDirection, includeImageCatalogs: boolean = false) {
    const result = await this.api.miscRequest<SearchProductsRequest, SearchProducts.Response>({
      endpoint: '/searchProducts',
      body: { currentPage, pageSize, filters, newFilters, sortBy, sortDirection, onlyWithImageCatalog, includeImageCatalogs },
      responseType: SearchProducts.Response,
      errorMessage: this.languageService.translateSync('ErrorLoadingProducts')
    })

    return result
  }

  async searchProductsByIds(ids: string[]) {
    const result = await this.api.miscRequest<ByIdsRequest, SearchProducts.Response>({
      endpoint: '/searchProductsByIds',
      body: { ids },
      responseType: SearchProducts.Response,
      errorMessage: this.languageService.translateSync('ErrorLoadingProducts')
    })
    return result
  }

  async exportProducts() {
    const result = await this.api.miscRequest({
      endpoint: '/exportProducts',
      responseType: ExportResponse,
      errorMessage: this.languageService.translateSync('ErrorExportProducts')
    })

    return result
  }

  async exportProductsWithFilters(filters: string[], newFilters: SearchProductsRequestFilters, onlyWithImageCatalog: boolean = false, currentPage = 0, pageSize = 0, sortBy?: SearchProductsRequest['sortBy'], sortDirection?: SortDirection, includeImageCatalogs: boolean = false) {
    const result = await this.api.miscRequest<SearchProductsRequest, ExportResponse>({
      endpoint: '/exportProductsWithFilters',
      body: { currentPage, pageSize, filters, newFilters, sortBy, sortDirection, onlyWithImageCatalog, includeImageCatalogs },
      responseType: ExportResponse,
      errorMessage: this.languageService.translateSync('ErrorLoadingProducts')
    })

    return result
  }

  async importProducts(url: string) {
    const result = await this.api.miscRequest<ImportRequest>({
      endpoint: '/importProducts',
      body: { url },
      errorMessage: this.languageService.translateSync('ErrorImportProducts')
    })

    return result
  }

  async deleteImageFromImageCatalog(productId: string, imageCatalogId: string, fileLocation: string) {
    const result = await this.api.miscRequest<DeleteImageFromImageCatalogRequest>({
      endpoint: '/deleteImageFromImageCatalog',
      body: { productId, imageCatalogId, fileLocation },
      errorMessage: this.languageService.translateSync('ErrorDeletingImageFromCatalog')
    })

    return result
  }

  async deleteImageCatalog(productId, imageCatalogId, isPrimary: boolean) {
    await this.api.miscRequest<DeleteImageCatalogRequest>({
      endpoint: '/deleteImageCatalog',
      body: { productId, imageCatalogId, isPrimary },
      errorMessage: this.languageService.translateSync('ErrorDeletingimageCatalog')
    })
  }

  //Category
  async addCategory(data: AddCategoryRequest) {
    const result = await this.api.miscRequest({
      endpoint: '/addCategory',
      body: data,
      responseType: AddCategoryResponse,
      errorMessage: this.languageService.translateSync('ErrorSaveCategory')
    })

    return result

  }

  async getCategoriesWithIds() {
    const result = await this.api.miscRequest({
      endpoint: '/listCategoriesWithIds',
      responseType: ListCategoriesWithIds.Response,
      errorMessage: this.languageService.translateSync('ErrorLoadingCategories')
    }, { isHideProgress: true })

    return result.arr
  }

  // Brand
  async addBrand(data: AddBrandRequest) {
    const result = await this.api.miscRequest({
      endpoint: '/addBrand',
      body: data,
      responseType: AddBrandResponse,
      errorMessage: this.languageService.translateSync('ErrorSaveBrand')
    })

    return result

  }

  async getBrandsWithIds() {
    const result = await this.api.miscRequest({
      endpoint: '/listBrandsWithIds',
      responseType: ListBrandsWithIds.Response,
      errorMessage: this.languageService.translateSync('ErrorLoadingBrands')
    }, { isHideProgress: true })

    return result.arr
  }

  async updateImageCatalog(productId: string, imageCatalogId: string, name: string, height: number, width: number, depth: number, isActive: boolean) {
    const result = await this.api.miscRequest<UpdateImageCatalogRequest, UpdateImageCatalogResponse>({
      endpoint: '/updateImageCatalogProduct',
      body: { productId, imageCatalogId, name, height, width, depth, isActive },
      responseType: UpdateImageCatalogResponse,
      errorMessage: this.languageService.translateSync('ErrorUpdatingImageCatalog')
    })

    return result
  }

  async updatePrimaryImageCatalog(productId: string, imageCatalogId: string, imageId: string) {
    await this.api.miscRequest<UpdatePrimaryImageCatalogRequest>({
      endpoint: '/updatePrimaryImageCatalog',
      body: { productId, imageCatalogId, imageId },
      errorMessage: this.languageService.translateSync('ErrorUpdatingImageCatalog')
    })
  }

  async updatePrimaryCatalog(productId: string, imageCatalogId: string) {
    await this.api.miscRequest<UpdatePrimaryCatalogRequest>({
      endpoint: '/updatePrimaryCatalog',
      body: { productId, imageCatalogId },
      errorMessage: this.languageService.translateSync('ErrorUpdatingImageCatalog')
    })
  }

  async uploadImageCatalog(productId, catalogId: string, fileUrl: string) {
    const result = await this.api.miscRequest<UploadImagesToCatalog.Request, UploadImagesToCatalog.Response>({
      endpoint: '/uploadImageToCatalog',
      body: { productId, imageCatalogId: catalogId, fileUrl },
      responseType: UploadImagesToCatalog.Response,
      errorMessage: this.languageService.translateSync('CorruptFilePleaseCheck')
    }, { isHideProgress: true })

    return result
  }

  async getExistingProducts(items: GetExistingProductsCatalogs.Request) {
    const result = await this.api.miscRequest<GetExistingProductsCatalogs.Request, GetExistingProductsCatalogs.Response>({
      endpoint: '/getExistingProducts',
      body: items,
      responseType: GetExistingProductsCatalogs.Response,
      errorMessage: this.languageService.translateSync('ErrorCheckingForExistingProductsAndCatalogs')
    })

    return result
  }

  // To Approve
  async approveImagesMc1(imagesId: string[]) {
    await this.api.miscRequest<ByIdsRequest>({
      endpoint: '/approveImages',
      body: { ids: imagesId },
      errorMessage: this.languageService.translateSync('ErrorApprovingImages')
    })
  }

  async approveImages(items: ApproveImages.Item[]) {
    await this.api.miscRequest<ApproveImages.Request>({
      endpoint: '/approveImages',
      body: { items },
      errorMessage: this.languageService.translateSync('ErrorApprovingImages')
    })
  }

  async unapproveImages(imagesId: string[]) {
    await this.api.miscRequest<ByIdsRequest>({
      endpoint: '/diapproveImages',
      body: { ids: imagesId },
      errorMessage: this.languageService.translateSync('ErrorUnapprovingImages')

    })
  }

  async countImagesToApprove(filterByImagesSource: SourceType, isHideProgress?: boolean) {
    const result = await this.api.miscRequest({
      endpoint: '/countImagesToApprove',
      body: { filterBy: filterByImagesSource },
      responseType: CountImagesToApproveResponse,
      errorMessage: 'Error loading unapproved images count',
    }, { isHideProgress })
    return result
  }

  async searchUnapprovedImages(currentPage: number, pageSize: number, filterByImagesSource: SourceType, filters: string[], newFilters) {
    const result = await this.api.miscRequest<ListProductsWithImagesToApproveRequest, ListProductsWithImagesToApprove.Response>({
      endpoint: '/listProductsWithImagesToApprove',
      body: { currentPage: currentPage, pageSize: pageSize, filterBy: filterByImagesSource, filters, newFilters },
      responseType: ListProductsWithImagesToApprove.Response,
      errorMessage: this.languageService.translateSync('ErrorLoadingUnapprovedImagesCount')
    })
    return result
  }

  async countProductCatalogImages(productId: string, catalogId: string) {
    const result = await this.api.miscRequest<CountProductCatalogImagesRequest, CountProductCatalogImagesResponse.Response>({
      endpoint: '/countProductCatalogImages',
      body: { productId, catalogId },
      responseType: CountProductCatalogImagesResponse.Response,
      errorMessage: this.languageService.translateSync('ErrorLoadingProductsAccuracy')
    })
    return result
  }

  async getCompanySettings() {
    const result = await this.api.miscRequest({
      endpoint: '/getCompanySettings',
      responseType: CompanySettingsResponse,
      errorMessage: this.languageService.translateSync('ErrorLoadingProductsAccuracy')
    })
    return result
  }

  async listImageCatalogsWithImagesToApprove(body: ListImageCatalogsWithImagesToApprove.Request) {
    const result = await this.api.miscRequest<ListImageCatalogsWithImagesToApprove.Request, ListImageCatalogsWithImagesToApprove.Response>({
      endpoint: '/listImageCatalogsWithImagesToApprove',
      body,
      responseType: ListProductsWithImagesToApprove.Response,
      errorMessage: this.languageService.translateSync('ErrorLoadingUnapprovedImagesCount')
    })
    return result

  }

  async searchImagesToApproveMc1(productId: string, imageCatalogId: string, currentPage: number, syncOnlyEditedImages: string, pageSize: number, filterBy: SourceType, sortDirection?: SortDirection, sortBy?: string, filters?: string[]) {
    const result = await this.api.miscRequest<ListImagesToApproveRequest, ListImagesToApprove.Response>({
      endpoint: '/listImagesToApprove',
      body: { productId, pageSize, imageCatalogId, syncOnlyEditedImages, filterBy, sortDirection, sortBy, filters },
      responseType: ListImagesToApprove.Response,
      errorMessage: this.languageService.translateSync('ErrorLoadingUnapprovedImages')
    })
    return result
  }

  async searchImagesToApprove(body: ListImagesToApproveRequest) {
    const result = await this.api.miscRequest<ListImagesToApproveRequest, ListImagesToApprove.Response>({
      endpoint: '/listImagesToApprove',
      body,
      responseType: ListImagesToApprove.Response,
      errorMessage: this.languageService.translateSync('ErrorLoadingUnapprovedImages')
    })
    return result
  }

  // Misc
  async createSignedUrl(ext: string, contentType: string, isHideProgress?: boolean) {
    const result = await this.api.miscRequest<CreateSignedUrlRequest, CreateSignedUrlRsponse>({
      endpoint: '/createSignedUrl',
      body: { isMobileReport: false, ext, contentType },
      responseType: CreateSignedUrlRsponse,
      errorMessage: this.languageService.translateSync('ErrorUploadingImage')
    }, { isHideProgress })

    return result
  }

  async createSignedUrlFull(body: CreateSignedUrlRequest, isHideProgress?: boolean) {
    const result = await this.api.miscRequest<CreateSignedUrlRequest, CreateSignedUrlRsponse>({
      endpoint: '/createSignedUrl',
      body,
      responseType: CreateSignedUrlRsponse,
      errorMessage: this.languageService.translateSync('ErrorUploadingImage')
    }, { isHideProgress })
    return result
  }

  async listStaticModels() {
    const result = await this.api.miscRequest({
      endpoint: '/listStaticModels',
      responseType: ListStaticModels.Response,
      errorMessage: this.languageService.translateSync('ErrorLoadingStaticModels')
    })

    return result.arr
  }

  async getComplianceReport(reportId: string) {
    const result = await this.api.miscRequest<ByIdRequest, ComplianceReport>({
      endpoint: '/getProcessedMobileReport',
      body: { id: reportId },
      errorMessage: this.languageService.translateSync('ErrorGettingComplianceReport')
    })
    return result
  }

  async getMobileReport(reportId: string) {
    const result = await this.api.miscRequest<ByIdRequest, ComplianceReport>({
      endpoint: '/getMobileReport',
      body: { id: reportId },
      errorMessage: this.languageService.translateSync('ErrorGettingMobileReport')
    })
    return result
  }

  async getMobileReportsExternalUrl() {
    const result = await this.api.miscRequest({
      endpoint: '/getMobileReportsUrl',
      responseType: GetMobileReportsUrlResponse,
      errorMessage: this.languageService.translateSync('ErrorGettingMobileReportsUrl')
    })
    return result
  }

  async getMobileReportsInternalUrl() {
    const result = await this.api.miscRequest({
      endpoint: '/getMobileReportsInternalUrl',
      responseType: GetMobileReportsUrlResponse,
      errorMessage: this.languageService.translateSync('ErrorGettingMobileReportsUrl')
    })
    return result
  }

  async getTotalReportsFromDashboard() {
    const result = await this.api.miscRequest({
      endpoint: '/getTotalReports',
      responseType: GetTotalReports.Response,
      errorMessage: this.languageService.translateSync('ErrorGettingMobileReportsUrl')
    })
    return result
  }

  async getNotifications() {
    return await this.api.miscRequest({
      endpoint: '/getNotifications',
      responseType: GetAllNotifications.Response,
      errorMessage: this.languageService.translateSync('ErrorGettingNotifications'),
    })
  }

  async checkNotifications() {
    return await this.api.miscRequest({
      endpoint: '/checkNotifications',
      responseType: GetAllNotifications.Response,
      errorMessage: this.languageService.translateSync('ErrorGettingNotifications'),
    })
  }

  async detectPlanogrmFromImage(planogramId: string, fileUrl: string) {
    return await this.api.miscRequest<SetPlanogramFromImageRequest, SetPlanogramFromImageResponse>({
      endpoint: '/detectPlanogramFromImage',
      body: { planogramId, fileUrl },
      responseType: SetPlanogramFromImageResponse,
    })
  }

  async getProductsByLabels(labels: string[]) {
    return await this.api.miscRequest({
      endpoint: '/getProductsByLabels',
      body: { ids: labels },
      responseType: ProductsByLabelsResponse,
      errorMessage: this.languageService.translateSync('ErrorGettingCatalogsByLabels'),
    })
  }

}