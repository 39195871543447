import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { LatLng } from '../api-structures/common';

export class MapPlace {
  id!: string
  searchedAdress!: string
  foundAddress?: string
  latLng?: LatLng
}

let apiLoaded: Observable<boolean>;

@Injectable({ providedIn: 'root' })
export class GoogleMapsService {

  private isLoading = false;

  constructor(private httpClient: HttpClient, private memoryStorageService: MemoryStorageService, private languageService: LanguageService) { }

  loadApi() {
    let lang = this.languageService.getLanguage()
    if (window.google && window.google.maps) apiLoaded = of(true)
    if (!apiLoaded && !this.isLoading) {
      this.isLoading = true
      apiLoaded = this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${this.memoryStorageService.mapsKey}&libraries=places&language=${lang}&sensor=false`, 'callback')
        .pipe(
          map(() => true),
          catchError(() => of(false)),
          finalize(() => {
            this.isLoading = false
          })
        );
    }

    return apiLoaded
  }

  async getPlaces(query: string): Promise<MapPlace[]> {
    const autocompleteService = new google.maps.places.AutocompleteService();

    try {
      return new Promise<MapPlace[]>((resolve, reject) => {
        autocompleteService.getPlacePredictions(
          {
            input: query,
            types: ["address"],
          },
          (predictions: any[], status: google.maps.places.PlacesServiceStatus) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              const allplaces: MapPlace[] = [];
              for (const item of predictions) {
                const singlePlace: MapPlace = {
                  id: item.place_id,
                  searchedAdress: query,
                  foundAddress: item.description
                };
                allplaces.push(singlePlace);
              }
              resolve(allplaces);
            } else {
              resolve([]);
            }
          }
        );
      });
    } catch (error) {
      throw error;
    }
  }

  async getGeoLocationOfPlace(selectedPlace: MapPlace): Promise<MapPlace | undefined> {
    const service = new google.maps.places.PlacesService(document.createElement('div'));

    try {

      return new Promise<MapPlace | undefined>((resolve, reject) => {
        service.getDetails(
          {
            placeId: selectedPlace.id,
            fields: ['formatted_address', 'geometry'],
          },
          (place: google.maps.places.PlaceResult, detailsStatus: google.maps.places.PlacesServiceStatus) => {
            if (detailsStatus === google.maps.places.PlacesServiceStatus.OK) {
              selectedPlace.foundAddress = place.formatted_address;
              selectedPlace.latLng = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              };
              resolve(selectedPlace);
            } else {
              resolve(undefined);
            }
          }
        );
      });

    } catch (error) {
      throw error;
    }
  }
}