<generic-table [title]="'RegisteredCustomers'|translate" [subTitle]="'CustomerListSubTitle' | translate" [displayedColumns]="displayedColumns" [data]="data"
  (addEvent)="add()" (editEvent)="onEdit($event)" (usersEvent)="onUsers($event)" (deleteEvent)="onDelete($event)" (filterEvent)="onFilter($event)"
  (importEvent)="uploadCsvFileBtnClick()" [importTooltip]="'externalId; name; category; address; location; territory; chain; taxId; customerCode'" 
  (exportEvent)="exportCustomers()" (refreshEvent)="refresh()" (paginateEvent)="onPaginate($event)" (modalFiltersEvent)="onModalFilters($event)" 
  (importEvent)="importCsvFile($event)" [importDialogData]="importDialogData"
  [showModalFilters]="true" [totalItens]="totalItens">
</generic-table>
<input type="file" (change)="importCsvFile(csvFileInput)" (click)="csvFileInput.value = null" [hidden]="true"
id="importCsvFileInput" accept=".csv" #csvFileInput >
<p-dialog [(visible)]="isShowModal" class="scroll-container">
  <p-header>{{ 'AddingMultipleClients' | translate }}</p-header>
  
  <p *ngFor="let item of lsMessages" >
      {{item}}
  </p>
  <div class="dialog-button-container">
      <button mat-flat-button color="primary" (click)="afterConfirmModal()">{{'Ok' |translate}}</button>
  </div>
</p-dialog>